.body {
    min-height: 100vh !important;
    &__logo {
        height: 45px;
        background-image: url('./logo.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: bottom center;
        margin: 10px 20px;
        margin: 10px 20px 10px 10px;
    }
    &__subtitle {
        color: white;
        text-align: center;
        margin-bottom: 10px;
    }
}