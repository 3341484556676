.timeline {
    display: inline-flex;
    flex-direction: row-reverse;
    margin-top: 20px;
    &:hover {
        cursor: grab;
    }

    &__sidebar {
        width: 224px;
        height: 100%;
        min-height: 400px;
        background-color: white;
        z-index: 2;
        margin-left: -24px;
        padding-left: 24px;
        padding-top: 40px;
        border-right: solid 1px #eee;
    }

    &__container {
        flex: 1;
        min-width: 1200px;
        z-index: 1;
    }
    
    &__header {
        display: flex;
        flex-direction: row;
        height: 40px;
        background-color: #eaeaea;
        width: 100%;

        &_time {
            height: 100%;
            width: 50px;
            background-color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            border-right: solid 1px #0000002e;
            border-bottom: solid 1px #0000002e;
        }
    }
}