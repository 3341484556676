.report {
    &__settings {
        table {
            width: 100%;
        }
        width: 100%;
        padding: 20px 10px;
        background-color: #fbfbfb;
        border-radius: 5px;
        border: solid 1px #00000021;
    }
    &__update {
        padding: 10px 0;
        display: flex;
        justify-content: center;
    }
}