.direction-dash-header {
    &__body {
        margin-top: 20px;
    }
    &__metrics {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        &>div {
            margin-right: 50px;
        }
    }
    &__navigator {
        margin-top: 15px;
    }
}