.big-number {
    &__value {
        display: flex;
        align-items: center;
        margin: 0;
        margin-right: 30px;
        font-size: 80px;
        font-weight: 700;
        line-height: 1.2em;
        .suffix {
            font-size: 0.5em;
            line-height: 1em;
        }
    }

    &__title {
        display: flex;
        align-items: center;
        font-size: 20px;
        margin-bottom: 8px;
    }

    &__prev-value {
        font-size: 16px;
        line-height: 2em;
    }
}