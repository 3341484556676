.weeks-line {
    justify-content: flex-end;
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    align-items: flex-end;
    &::-webkit-scrollbar {
        width: 0 !important;
    }

    &__bar {
        margin-right: 1px;
        // background-color: #00a000;
        flex: 0 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white !important;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        p {
            flex: 1;
            margin-bottom: 0;
        }
        &_active::after {
            background-color: black;
            content: '';
            height: 2px;
            width: 100%;
            display: -webkit-box;
            opacity: 0.75;
        }
        &:hover {
            opacity: 0.9;
        }
        &:hover &_hover {
            display: block !important;
            position: absolute;
            color: white;
            background-color: black;
            padding: 4px 8px;
            border-radius: 4px;
            margin-top: -46px;
            font-size: 14px;
            font-weight: 400;
            min-width: 120px;
            text-align: center;
            white-space: nowrap;
        }
    }
}