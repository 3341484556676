@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&display=swap&subset=cyrillic);
body {
  margin: 0;
  font-family: 'Open Sans', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000000a6;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-input {
  line-height: 1.5;
}
.ant-dropdown-menu {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15) !important; }

.ant-dropdown-menu-item {
  height: auto !important;
  line-height: 22px !important;
  padding: 5px 12px !important; }

.body {
  min-height: 100vh !important; }
  .body__logo {
    height: 45px;
    background-image: url(/static/media/logo.33b77706.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom center;
    margin: 10px 20px;
    margin: 10px 20px 10px 10px; }
  .body__subtitle {
    color: white;
    text-align: center;
    margin-bottom: 10px; }

.weeks-line {
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  align-items: flex-end; }
  .weeks-line::-webkit-scrollbar {
    width: 0 !important; }
  .weeks-line__bar {
    margin-right: 1px;
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white !important;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    .weeks-line__bar p {
      flex: 1 1;
      margin-bottom: 0; }
    .weeks-line__bar_active::after {
      background-color: black;
      content: '';
      height: 2px;
      width: 100%;
      display: -webkit-box;
      opacity: 0.75; }
    .weeks-line__bar:hover {
      opacity: 0.9; }
    .weeks-line__bar:hover .weeks-line__bar_hover {
      display: block !important;
      position: absolute;
      color: white;
      background-color: black;
      padding: 4px 8px;
      border-radius: 4px;
      margin-top: -46px;
      font-size: 14px;
      font-weight: 400;
      min-width: 120px;
      text-align: center;
      white-space: nowrap; }

.big-number__value {
  display: flex;
  align-items: center;
  margin: 0;
  margin-right: 30px;
  font-size: 80px;
  font-weight: 700;
  line-height: 1.2em; }
  .big-number__value .suffix {
    font-size: 0.5em;
    line-height: 1em; }

.big-number__title {
  display: flex;
  align-items: center;
  font-size: 20px;
  margin-bottom: 8px; }

.big-number__prev-value {
  font-size: 16px;
  line-height: 2em; }

.direction-dash-header__body {
  margin-top: 20px; }

.direction-dash-header__metrics {
  display: flex;
  flex-direction: row;
  align-items: baseline; }
  .direction-dash-header__metrics > div {
    margin-right: 50px; }

.direction-dash-header__navigator {
  margin-top: 15px; }

.items-list {
  font-size: 14px;
  align-items: center;
  font-size: 14px;
  align-items: center;
  border-bottom: solid 1px #eaeaea; }
  .items-list__title {
    font-weight: 400;
    padding: 12px 0;
    padding-right: 10px;
    align-items: center;
    white-space: nowrap; }

.filters {
  display: flex;
  flex-wrap: wrap;
  align-items: center; }
  .filters__select {
    min-width: 200px; }

.report__settings {
  width: 100%;
  padding: 20px 10px;
  background-color: #fbfbfb;
  border-radius: 5px;
  border: solid 1px #00000021; }
  .report__settings table {
    width: 100%; }

.report__update {
  padding: 10px 0;
  display: flex;
  justify-content: center; }

.bi-morda__header {
  padding: 40px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .bi-morda__header div {
    max-width: 600px;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center; }

.bi-morda__body {
  margin-top: 80px; }
  .bi-morda__body h2, .bi-morda__body h3 {
    text-align: center; }

.bi-morda__cards {
  display: flex;
  flex-direction: row;
  max-height: 1200px;
  margin-top: 20px;
  flex-flow: row wrap; }
  .bi-morda__cards-item {
    padding: 7.5px; }
    .bi-morda__cards-item .ant-card-meta-title {
      white-space: inherit; }

.timeline {
  display: inline-flex;
  flex-direction: row-reverse;
  margin-top: 20px; }
  .timeline:hover {
    cursor: -webkit-grab;
    cursor: grab; }
  .timeline__sidebar {
    width: 224px;
    height: 100%;
    min-height: 400px;
    background-color: white;
    z-index: 2;
    margin-left: -24px;
    padding-left: 24px;
    padding-top: 40px;
    border-right: solid 1px #eee; }
  .timeline__container {
    flex: 1 1;
    min-width: 1200px;
    z-index: 1; }
  .timeline__header {
    display: flex;
    flex-direction: row;
    height: 40px;
    background-color: #eaeaea;
    width: 100%; }
    .timeline__header_time {
      height: 100%;
      width: 50px;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: solid 1px #0000002e;
      border-bottom: solid 1px #0000002e; }


