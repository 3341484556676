.items-list {
    font-size: 14px;
    align-items: center;
    font-size: 14px;
    align-items: center;
    border-bottom: solid 1px #eaeaea;

    &__title {
        font-weight: 400;
        padding: 12px 0;
        padding-right: 10px;
        align-items: center;
        white-space: nowrap;
    }
}