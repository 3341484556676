.bi-morda {
    &__header {
        padding: 40px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        div {
            max-width: 600px;
            min-height: 200px;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    &__body {
        margin-top: 80px;
        h2, h3 {
            text-align: center;
        }
    }

    &__cards {
        display: flex;
        flex-direction: row;
        max-height: 1200px;
        margin-top: 20px;
        flex-flow: row wrap;

        &-item {
            padding: 7.5px;

            .ant-card-meta-title {
                white-space: inherit;
            }
        }
    }
}